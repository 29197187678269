import "@expo/browser-polyfill";
import "intl-pluralrules";

import * as SentryBrowser from "@sentry/browser";
import * as Sentry from "@sentry/react";
import constants from "expo-constants";
import * as Crypto from "expo-crypto";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as ReactNative from "react-native";
import { Platform } from "react-native";
import { setCrypto } from "sutro-common";
import { InterpreterBundle } from "sutro-common/interpreter/bundle";
import {
  STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES,
  STUDIO_PREVIEW_MESSAGE_TYPES,
  StudioCommLayer,
} from "sutro-common/studio-interpreter-common-types";
import {
  BuildInProgress,
  globalNamespace,
  inIframe,
  reloadApp,
  SentryErrorBoundaryFallbackComponent,
  StudioAppError,
  StudioAppLoading,
  StudioComm,
  SutroAppContainer,
  useGeneratedTheme,
} from "sutro-interpreter";

import { loadInterpreterBundle } from "./loadInterpreterBundle";

globalNamespace.React = React;
globalNamespace.ReactNative = ReactNative;

import { DEFAULT_THEME } from "sutro-common/theme-definition";
import useConstant from "use-constant";

import customComponents from "./custom-components.js";
setCrypto(Crypto);

const sentryConfig = {
  dsn: "https://f877824439694459ad6f34c1f3b4722d@o1183205.ingest.sentry.io/6335879",
  tracesSampleRate: 0.5,
  enabled:
    constants.expoConfig?.extra?.isDev !== true &&
    constants.expoConfig?.extra?.isProdTest !== true,
  integrations: [],
};

if (Platform.OS === "web") {
  SentryBrowser.init({
    ...sentryConfig,
    integrations: [
      ...sentryConfig.integrations,
      // This is only for web
      new SentryBrowser.Replay({
        // this was discussed in person, will be changed to dynamic value once we setup sentry releases
        sessionSampleRate: 0.25,
        errorSampleRate: 0.5,
      }),
    ],
  });
} else {
  Sentry.init(sentryConfig);
}

/**
 * If we're in an iFrame, then we should forward the console messages to the Studio
 *
 * We can just replace the console functions, because there's no way for us to see them
 * logged in the console anyway.
 */
const instrumentConsole = (commLayer: StudioCommLayer | null) => {
  (["log", "warn", "error", "info", "debug"] as const).forEach((level) => {
    console[level] = (...args: any[]) => {
      try {
        commLayer?.sendMessage({
          type: STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES.CONSOLE,
          payload: {
            level,
            args,
          },
        });
      } catch (e) {
        commLayer?.sendMessage({
          type: STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES.CONSOLE,
          payload: {
            level: "debug",
            args: [
              "Could not forward console message. This is probably not an error and can be ignored.",
            ],
          },
        });
      }
    };
  });
};

export default function App() {
  const [interpreterBundle, setInterpreterBundle] =
    useState<InterpreterBundle | null>(null);
  const interpreterBundleRef = useRef(interpreterBundle);
  interpreterBundleRef.current = interpreterBundle;

  const [scheme, setScheme] = useState<ReactNative.ColorSchemeName | undefined>(
    undefined
  );
  const [isBundleError, setIsBundleError] = useState<boolean>(false);

  const studioCommLayer = useConstant<StudioCommLayer | null>(() =>
    inIframe() ? new StudioComm(constants.expoConfig?.extra?.studioUrl) : null
  );

  useEffect(() => {
    if (inIframe()) {
      instrumentConsole(studioCommLayer);
    }
  }, [studioCommLayer]);

  useEffect(() => {
    studioCommLayer?.onMessage(
      STUDIO_PREVIEW_MESSAGE_TYPES.BUNDLE,
      (message) => {
        setIsBundleError(false);
        setInterpreterBundle(message.payload as unknown as InterpreterBundle);
      }
    );
    studioCommLayer?.onMessage(
      STUDIO_PREVIEW_MESSAGE_TYPES.SCHEME,
      (message) => {
        setScheme(message.payload);
      }
    );
    studioCommLayer?.onMessage(
      STUDIO_PREVIEW_MESSAGE_TYPES.THEME,
      (message) => {
        // Use a Ref for interpreterBundle so the effect doesn't rerun
        if (interpreterBundleRef.current !== null) {
          setIsBundleError(false);
          setInterpreterBundle({
            ...interpreterBundleRef.current,
            theme: message.payload,
          });
        }
      }
    );
    studioCommLayer?.onMessage(
      STUDIO_PREVIEW_MESSAGE_TYPES.BUNDLE_ERROR,
      () => {
        setIsBundleError(true);
      }
    );

    // Cleanup function to remove the event listener when the provider unmounts
    return () => {
      studioCommLayer?.cleanUp();
    };
  }, [studioCommLayer]);

  const darkTheme = useGeneratedTheme({
    themeDefinition: interpreterBundleRef.current?.theme ?? DEFAULT_THEME,
    fontsLoaded: true,
    systemColorScheme: "dark",
  });
  const lightTheme = useGeneratedTheme({
    themeDefinition: interpreterBundleRef.current?.theme ?? DEFAULT_THEME,
    fontsLoaded: true,
    systemColorScheme: "light",
  });

  useEffect(() => {
    studioCommLayer?.sendMessage({
      type: STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES.GENERATED_THEME,
      payload: {
        darkTheme: { ...darkTheme, t: undefined },
        lightTheme: { ...lightTheme, t: undefined },
      },
    });
  }, [darkTheme, lightTheme, studioCommLayer]);

  const tryAgain = useCallback(() => {
    if (!inIframe()) {
      loadInterpreterBundle()
        // Don't override bundle if value has already been set
        .then((value) => setInterpreterBundle((oldValue) => oldValue || value))
        .catch(() => {});
    }
  }, []);

  useEffect(() => {
    if (interpreterBundle !== null) {
      return;
    }
    tryAgain();
  }, [tryAgain, interpreterBundle]);

  if (isBundleError && inIframe()) {
    return <StudioAppError />;
  }

  if (interpreterBundle === null) {
    if (inIframe()) {
      return <StudioAppLoading />;
    }
    return <BuildInProgress tryAgain={tryAgain} />;
  }
  return (
    <Sentry.ErrorBoundary
      fallback={(errorData) => (
        <SentryErrorBoundaryFallbackComponent
          errorData={errorData}
          reload={reloadApp}
          theme={interpreterBundle?.theme}
        />
      )}
    >
      <SutroAppContainer
        interpreterBundle={interpreterBundle}
        customComponents={customComponents as Record<string, React.FC>}
        scheme={scheme}
        studioCommLayer={studioCommLayer}
      />
    </Sentry.ErrorBoundary>
  );
}
